import { Component } from 'preact';
import h from '../../cssScope';
import { Text } from './templates/Text';
import { TextArea } from './templates/TextArea';
import { Phone } from './templates/Phone';
import { Email } from './templates/Email';
import { Country } from './templates/Country';
import { City } from './templates/City';
import { RadioGroup } from './templates/RadioGroup';
import { FieldsKeys } from '../../constants';
import { DeliveryMethod } from './templates/DeliveryMethod';
import { Terminal } from './templates/Terminal';
import { Ozon } from './templates/Ozon';
import { Yandex } from './templates/Yandex';
import { InlineAddress } from './templates/InlineAddress';
import { AddressMap } from './templates/AddressMap';
import { CustomerType } from './templates/CustomerType';
import { Inn } from './templates/Inn';
export const TEMPLATES = {
    Text,
    Phone,
    Email,
    Country,
    City,
    RadioGroup,
    DeliveryMethod,
    TextArea,
    Terminal,
    Ozon,
    Yandex,
    InlineAddress,
    AddressMap,
    CustomerType,
    Inn
};
const FIELDS_CONFIG = {
    [FieldsKeys.FirstName]: { type: 'Text' },
    [FieldsKeys.LastName]: { type: 'Text' },
    [FieldsKeys.Phone]: { type: 'Phone' },
    [FieldsKeys.Email]: { type: 'Email' },
    [FieldsKeys.Country]: { type: 'Country' },
    [FieldsKeys.City]: { type: 'City' },
    [FieldsKeys.Address]: { type: 'Text' },
    [FieldsKeys.Comment]: { type: 'TextArea' },
    [FieldsKeys.Apartment]: { type: 'Text' },
    [FieldsKeys.ZipCode]: { type: 'Text' },
    [FieldsKeys.PaymentMethod]: { type: 'RadioGroup' },
    [FieldsKeys.DeliveryMethod]: { type: 'DeliveryMethod' },
    [FieldsKeys.Terminal]: { type: 'Terminal' },
    [FieldsKeys.Ozon]: { type: 'Ozon' },
    [FieldsKeys.Yandex]: { type: 'Yandex' },
    [FieldsKeys.InlineAddress]: { type: 'InlineAddress' },
    [FieldsKeys.StreetAddress]: { type: 'Text' },
    [FieldsKeys.AddressMap]: { type: 'AddressMap' },
    [FieldsKeys.CustomerType]: { type: 'CustomerType' },
    [FieldsKeys.Inn]: { type: 'Inn' }
};
export class Field extends Component {
    constructor() {
        super(...arguments);
        this.handleChange = (value) => {
            const { field: { key }, onChange } = this.props;
            onChange(key, value);
        };
    }
    get fieldInputName() {
        const { field: { key }, formName } = this.props;
        return `${formName}[${key}]`;
    }
    get type() {
        const config = FIELDS_CONFIG[this.props.field.key];
        return config && config.type ? config.type : 'Text';
    }
    render() {
        const { onChange, formName: _formName, ...rest } = this.props;
        let FieldTemplate = TEMPLATES[this.type];
        if (!FieldTemplate) {
            return null;
        }
        return (h(FieldTemplate, Object.assign({ name: this.fieldInputName, onChange: this.handleChange, onChangeValue: onChange }, rest)));
    }
}
