import Tooltip from 'core/components/UI/Tooltip';
import htmlClassNames from 'core/utils/htmlClassNames';
import { Component } from 'preact';
import { GtmAddressEvents } from 'site/VControllers/components/Checkout/constants';
import { OPERATION_CHANGE_GTM_ADDRESS_EVENT } from 'site/VControllers/components/Checkout/operations/gtm';
import { defineCityId } from 'site/VControllers/SinglePageCheckout/services/defineCityId';
import { INLINE_ADDRESS_ID_REGEXP, PART_SUBSTITUTIONS } from 'site/VControllers/SinglePageCheckout/services/fetchAddresses';
import { FieldsKeys } from '../../..';
import h from '../../../cssScope';
import { AddressSuggestions } from 'luxury_theme.mobile/components/AddressSuggestions';
import { ContentType } from 'site/VControllers/SinglePageCheckout/constants';
const BASE_ID_REGEX = /^\d{13,}$/;
export class InlineAddress extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            inputRef: undefined
        };
        this.awaitCityId = null;
        this.awaitImproveAddress = false;
        this.handleInputRef = (el) => {
            this.setState({ inputRef: el });
        };
        // to allow commit input address
        this.handleChangeSearch = (event) => {
            var _a, _b;
            const value = event.target.value;
            this.awaitImproveAddress = true;
            (_b = (_a = this.props).onChangeValue) === null || _b === void 0 ? void 0 : _b.call(_a, FieldsKeys.Address, value);
        };
        this.saveSelectedCityId = (item) => {
            var _a, _b;
            const itemId = parseItemId(item);
            if (!(itemId && this.awaitCityId)) {
                if (this.awaitImproveAddress && !this.props.disabled)
                    setTimeout(this.compareAndSelectCityForUser, 100);
                this.awaitImproveAddress = false;
                return;
            }
            (_b = (_a = this.props).onChangeValue) === null || _b === void 0 ? void 0 : _b.call(_a, FieldsKeys.City, this.awaitCityId);
            this.awaitCityId = null;
        };
        //handleFinalBlur = () => {
        //  this.saveSelectedCityId(item)
        //}
        // we need change city for user to show real info about delivery
        this.compareAndSelectCityForUser = async () => {
            var _a, _b;
            const { formFields, field } = this.props;
            const addressField = formFields.find(field => field.key === FieldsKeys.Address);
            const cityField = formFields.find(field => field.key === FieldsKeys.City);
            if (!(addressField && cityField))
                return;
            // if an user doesn't change a city by autocomplete we know that city can be found in loaded from backend cities 
            const cityItem = cityField.items.find(item => item.id === cityField.value);
            // if we cannot find the city then user have changed city 
            if (!cityItem)
                return;
            const regexp = new RegExp(`${cityItem.text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}`, 'i');
            const address = addressField.value.toString();
            // if value exists then everything is ok
            if (!address || regexp.test(address))
                return;
            const defineResult = await defineCityId(cityItem.id.toString(), address);
            if (!defineResult)
                return;
            const parsedId = parseItemId({ id: defineResult.id, text: '' });
            if (parsedId) {
                field.value ? setTimeout(() => this.saveSearchValue(defineResult.address), 500) : this.saveSearchValue(defineResult.address);
                (_b = (_a = this.props).onChangeValue) === null || _b === void 0 ? void 0 : _b.call(_a, FieldsKeys.City, parsedId);
                this.props.onChange(null);
            }
        };
        this.saveSearchValue = (input) => {
            const inputRef = this.state.inputRef || this.virtualInputRef;
            if (!inputRef)
                return;
            inputRef.value = input;
            // we need to trigger update state from native event. to show user changed information
            // Current architecture of baseAutocomplete doesn't allow to pass searchValue from parent component
            inputRef.dispatchEvent(new Event('input'));
        };
        this.handleSuggestionSelect = (suggestion) => {
            var _a, _b, _c, _d;
            if (!suggestion) {
                return;
            }
            (_b = (_a = this.context) === null || _a === void 0 ? void 0 : _a.store) === null || _b === void 0 ? void 0 : _b.operate(OPERATION_CHANGE_GTM_ADDRESS_EVENT, GtmAddressEvents.AutocompleteAddress);
            const itemId = buildDaData(suggestion);
            if (suggestion.data.flat) {
                // this.props.onChangeValue?.(FieldsKeys.Apartment, suggestion.data.flat)
            }
            this.awaitCityId = null;
            this.props.onChange(itemId);
            (_d = (_c = this.props).onChangeValue) === null || _d === void 0 ? void 0 : _d.call(_c, FieldsKeys.Address, suggestion.value);
        };
    }
    field(key) {
        return this.props.formFields.find(v => v.key === key);
    }
    get isCountryBlank() {
        const countryField = this.field(FieldsKeys.Country);
        if (!countryField)
            return false;
        return !countryField.value;
    }
    get defaultSearchValue() {
        var _a;
        const { items, value } = this.props.field;
        if (items.length === 1 && !value && (!items[0].children || items[0].children.length === 0)) {
            // preselected city name
            return `${(_a = items[0].searchText) !== null && _a !== void 0 ? _a : items[0].text}, `;
        }
        return undefined;
    }
    get currentSavedAddressText() {
        return buildCurrentSavedAddressText(this.props.field);
    }
    render() {
        var _a, _b, _c;
        const { field, name, disabled, useHidden } = this.props;
        const { inputRef } = this.state;
        const defaultSearchValue = this.defaultSearchValue;
        const isMobile = Env.version === 'mobile';
        const notice = (_b = (_a = this.context) === null || _a === void 0 ? void 0 : _a.store) === null || _b === void 0 ? void 0 : _b.getState().notices[FieldsKeys.InlineAddress];
        const countryField = this.field(FieldsKeys.Country);
        const countryText = (countryField === null || countryField === void 0 ? void 0 : countryField.value) ? (_c = (countryField.items.find((v) => v.id === countryField.value))) === null || _c === void 0 ? void 0 : _c.text : null;
        return (h("div", { className: 'ab-test' },
            this.currentSavedAddressText ? (h("p", { className: 'sb-Row_itemHint' },
                "\u0412\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0439 \u0430\u0434\u0440\u0435\u0441: ",
                h("strong", null, this.currentSavedAddressText))) : (h("p", { className: 'sb-Row_itemHint' }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0430\u0434\u0440\u0435\u0441 \u043D\u0430 \u043A\u0430\u0440\u0442\u0435 \u0438\u043B\u0438 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0439\u0442\u0435 \u043F\u043E\u0438\u0441\u043A")),
            h("div", { className: htmlClassNames('ui-Input', { '__text': !isMobile, [`__${notice === null || notice === void 0 ? void 0 : notice.type}`]: !!notice }) },
                useHidden && h("input", { ref: this.handleInputRef, name: name, type: 'text', value: field.value }),
                (!useHidden || inputRef) && (h(AddressSuggestions, { token: Env.daData.token, containerClassName: 'ui-Input_autocomplete __autocompleteFixed __labelFloated', suggestionsClassName: 's-AutocompleteTree ui-Input_items __fixedList __open', suggestionClassName: 's-AutocompleteTree_b-Item __root __ab-test', currentSuggestionClassName: '__selected', highlightClassName: 's-AutocompleteTree_b-Highlighted', count: 6, customInput: isMobile ? 'textarea' : 'input', delay: 400, minChars: 1, httpCache: true, onChange: (suggestion) => this.handleSuggestionSelect(suggestion), filterFromBound: 'city', filterToBound: 'house', filterLocations: countryText && [{ country: countryText }], labelText: field.label, selectOnBlur: field.value ? false : true, inputProps: {
                        disabled: disabled || this.isCountryBlank,
                        onChange: (input) => this.handleChangeSearch(input)
                    }, defaultQuery: this.currentSavedAddressText || defaultSearchValue })),
                this.isCountryBlank && h(Tooltip, { label: '\u0421\u043D\u0430\u0447\u0430\u043B\u0430 \u0432\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u0442\u0440\u0430\u043D\u0443' }),
                notice && h(Tooltip, { label: notice.message }))));
    }
}
function parseItemId(item) {
    var _a;
    const itemId = (_a = item.id) === null || _a === void 0 ? void 0 : _a.toString();
    const matchRes = itemId === null || itemId === void 0 ? void 0 : itemId.match(INLINE_ADDRESS_ID_REGEXP);
    let cityId;
    if (matchRes) {
        cityId = matchRes[1];
    }
    if (!cityId && itemId && BASE_ID_REGEX.test(itemId))
        cityId = itemId;
    return cityId;
}
function buildCurrentSavedAddressText(field) {
    if (!field.value)
        return null;
    let text = null;
    const recurse = (items) => {
        items.forEach(item => {
            if (item.id === field.value) {
                text = item.searchText;
                return;
            }
            if (item.children) {
                recurse(item.children);
            }
        });
    };
    recurse(field.items);
    return text;
}
const NAME_SUBSTITUTIONS = {
    'Саха /Якутия/': 'Саха (Якутия)'
};
function joinParts(parts) {
    return parts.reduce((acc, [type, name]) => {
        var _a;
        if (!(name || type))
            return acc;
        const humanType = type ? `${(_a = PART_SUBSTITUTIONS[type]) !== null && _a !== void 0 ? _a : `${type}.`} ` : '';
        const humanName = name ? NAME_SUBSTITUTIONS[name.toString()] || name : '';
        acc += `${acc ? ', ' : ''}${humanType}${humanName}`;
        return acc;
    }, '');
}
function buildDaData(suggestion) {
    if (!suggestion)
        return null;
    const data = suggestion.data;
    const { cityKladrId, postalCode, settlementKladrId, settlementType } = data;
    let { houseKladrId, houseFiasId } = data;
    let fakePart = undefined;
    if (!houseKladrId && data.house) {
        houseKladrId = data.kladrId;
        houseFiasId = data.fiasId;
        fakePart = data.house;
    }
    const idSettlementValid = settlementType !== 'р-н';
    const block = joinParts([[data.blockTypeFull, data.block]]);
    const parts = [
        [data.streetType, data.street],
        [data.houseType, block ? `${data.house} ${block}` : data.house]
    ];
    const idParts = [
        [ContentType.City, settlementKladrId && idSettlementValid ? settlementKladrId : cityKladrId],
        [ContentType.Building, [houseKladrId, houseFiasId, postalCode, fakePart].filter(item => !!item).join(':')]
    ];
    const text = joinParts(parts);
    const id = idParts.reduce((acc, [type, id]) => id ? `${acc}${acc ? ':' : ''}${type}/${id}` : acc, '');
    if (text) {
        return id;
    }
    else {
        return cityKladrId;
    }
}
